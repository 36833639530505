import { useState,useEffect } from "react";
import { useNavigate } from "react-router-dom";
import eclipse4 from "../../assets/Ellipse 4.png";
import eclipse2 from "../../assets/Ellipse 2.png";
import eclipse3 from "../../assets/Ellipse 3.png";
import eclipse5 from "../../assets/Ellipse 5.png";
import { Verify } from 'react-puzzle-captcha';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { GoogleLogin } from '@react-oauth/google';
import Modal from 'react-modal'
import spincoin from './spincoin.gif'
const SignUp = ({ setsign, setmodel }) => {
  
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [continueAs, setContinueAs] = useState("");
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [captcha, setcaptcha] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    setsign(false);
    if (confirmPassword === password) {
      if (captcha) {
        let c = {
          email: email,
          firstName: firstName,
          lastName: lastName,
          password: password,
          accounttype: continueAs,
          signinwithgoogle: false,
        };
        fetch("https://api.abiv.in/signup", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(c),
        })
          .then((response) => response.json())
          .then((data) => {
            localStorage.setItem("auth-token", data.token);
            localStorage.setItem(
              "user-data",
              JSON.stringify({
                email: email,
                firstName: firstName,
                lastName: lastName,
                accounttype: continueAs,
              })
            );
            setmodel(false);
            window.location.reload();
          })
          .catch((error) => {
            console.error("Error:", error);
          });
      } else {
        alert("You need to validate captcha");
      }
    } else {
      alert("Password didn't match with Confirm Password");
    }
  };

  return (
    <>
    {!isModalOpen1 && 
    <div className="flex justify-center items-center min-h-screen bg-gray-100">
       
      <div className="bg-white p-4 rounded-lg shadow-lg w-full max-w-sm">
      
      <div className="mt-2">
            <GoogleOAuthProvider clientId="594536791781-6640scot0ufth8ol2onnnrg3tqo088ih.apps.googleusercontent.com">
              <GoogleLogin
                onSuccess={(credentialResponse) => {
                  console.log(credentialResponse);
                  const token = credentialResponse.credential;
                  console.log(token);
                  setTimeout(() => setIsModalOpen1(true), 0);

                  fetch(
                    "https://www.googleapis.com/oauth2/v3/tokeninfo?id_token=" +
                      token
                  )
                    .then((response) => response.json())
                    .then((data) => {
                      console.log("User Info:", data);
                      const { email } = data;
                      console.log("Email:", email);
                      const { picture } = data;
                      const { family_name, given_name } = data;
                      localStorage.setItem(
                        "user-data",
                        JSON.stringify({
                          email: email,
                          firstName: firstName,
                          lastName: lastName,
                          accounttype: continueAs,
                          avatar: picture,
                        })
                      );
                      console.log("Picture:", picture);

                      fetch("https://api.abiv.in/signup", {
                        method: "POST",
                        headers: {
                          "Content-Type": "application/json",
                        },
                        body: JSON.stringify({
                          email,
                          avatar: picture,
                          signinwithgoogle: true,
                          firsName: family_name,
                          lastName: given_name,
                        }),
                      })
                        .then((response) => response.json())
                        .then((data) => {
                          localStorage.setItem("auth-token", data.token);
                          console.log("Success:", data); 
                
                        })
                        .catch((error) => {
                          console.error("Error:", error);
                        });
                    })
                    .catch((error) => {
                      console.error("Error fetching user info:", error);
                    });
                }}
                onError={() => {
                  console.log("Login Failed");
                }}
              />
            </GoogleOAuthProvider>
          </div>
          <div className="flex mt-2 justify-center items-center space-x-2">
            <strong>OR</strong> 
            </div>
        <h2 className="text-lg  mb-3 text-center">{localStorage.getItem('glanguage')==='english'?'Sign Up':'साइन अप करें'}</h2>
        <form onSubmit={handleSubmit} className="space-y-2">
          <div>
            <label className="block text-sm font-medium text-gray-700">{localStorage.getItem('glanguage')==='english'?'Your email':'आपका ईमेल'}</label>
            <input
              type="email"
              className="mt-1 block w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="flex space-x-2">
            <div className="w-1/2">
              <label className="block text-sm font-medium text-gray-700">{localStorage.getItem('glanguage')==='english'?'First Name':'पहला नाम'}</label>
              <input
                type="text"
                className="mt-1 block w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                required
              />
            </div>
            <div className="w-1/2">
              <label className="block text-sm font-medium text-gray-700">{localStorage.getItem('glanguage')==='english'?'Last Name':'उपनाम'}</label>
              <input
                type="text"
                className="mt-1 block w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                required
              />
            </div>
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">{localStorage.getItem('glanguage')==='english'?'Your password':'आपका पासवर्ड'}</label>
            <input
              type="password"
              className="mt-1 block w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">{localStorage.getItem('glanguage')==='english'?'Confirm password':'पासवर्ड की पुष्टि कीजिये'}</label>
            <input
              type="password"
              className="mt-1 block w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">{localStorage.getItem('glanguage')==='english'?'Continue as':'विकल्प'}</label>
            <select
              className="mt-1 block w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              value={continueAs}
              onChange={(e) => setContinueAs(e.target.value)}
              required
            >
              <option value="" disabled>{localStorage.getItem('glanguage')==='english'?'Choose an option':'कोई विकल्प चुनें'}</option>
              <option value="teacher">{localStorage.getItem('glanguage')==='english'?'Teacher':'अध्यापक'}</option>
              <option value="student">{localStorage.getItem('glanguage')==='english'?'Student':'विद्यार्थी'}</option>
            </select>
          </div>
          <div className="mt-2">
            <Verify
              width={240}
              height={80}
              visible={true}
              onSuccess={() => setcaptcha(true)}
              onFail={() => setcaptcha(false)}
              onRefresh={() => console.log("refreshed")}
            />
          </div>
          <div className="mt-1" on onClick={()=>{window.open("https://abiv.in/terms")}}>
            {localStorage.getItem('glanguage')==='english'?'Terms & Conditions':'नियम और शर्तें'}
          </div>
        
          <button
            type="submit"
            className="w-full py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            {localStorage.getItem('glanguage')==='english'?'Confirm Credentials':'क्रेडेंशियल्स की पुष्टि करें'}
          </button>
        </form>
      
      </div>
      
    </div>}
    {isModalOpen1 && 
    <>
      <img src={spincoin} alt="spincoin" className="w-20 h-20 mx-auto" />
      <br></br>5 coins added Successfully to your account<br></br>
      <div className="flex justify-center">
      <button  className="mt-4 px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition " onClick={()=>{setIsModalOpen1(false);localStorage.setItem("togglersignup",'false')  ;        localStorage.setItem("signup", "False");
;                        setmodel(false);
;                  setsign(false);
window.location.reload()}}>Get Started</button></div>
    </>
  }</>
  );
};

export default SignUp;
