import React from 'react';
import "./CSS/Page4.css";
import img1 from '../assets/isometric view of man working online.png';
import img2 from '../assets/teacher with pointer and paper.png';
import img3 from '../assets/Teacher in empty classroom throwing her hands up.png';
import img4 from '../assets/young people brainstorming.png';
import { RiCheckboxCircleLine } from "react-icons/ri";

const Page4 = () => {
    const card = [{
        text1: localStorage.getItem('glanguage')==='english'?"Intelligent Study Hub":"बुद्धिमान अध्ययन केंद्र",
        text2: localStorage.getItem('glanguage')==='english'?"Upload PDFs, Images & Text":"पीडीएफ, छवियां और टेक्स्ट अपलोड करें",
        text3: localStorage.getItem('glanguage')==='english'?"AI-Powered Explanations":"एआई-संचालित व्याख्याएं",
        text4: localStorage.getItem('glanguage')==='english'?"Quick Topic Summaries":"त्वरित विषय सारांश",
        text5: localStorage.getItem('glanguage')==='english'?"Learn in Multiple Languages":"कई भाषाओं में सीखें",
    
    },{
        text1: localStorage.getItem('glanguage')==='english'?"Smart Exam Mode":"स्मार्ट परीक्षा मोड",
        text2: localStorage.getItem('glanguage')==='english'?"MCQ & Subjective Assessments":"एमसीक्यू और विषयात्मक मूल्यांकन",
        text3: localStorage.getItem('glanguage')==='english'?"AI-Curated Questions":"एआई-क्यूरेटेड प्रश्न",
        text4: localStorage.getItem('glanguage')==='english'?"Real-Time Results & Reports":"वास्तविक समय के परिणाम और रिपोर्ट",
        text5: localStorage.getItem('glanguage')==='english'?"Track Progress & Improvement":"प्रगति और सुधार को ट्रैक करें",
    
    },{
        text1: localStorage.getItem('glanguage')==='english'?"Immersive Learning Zone":"इमर्सिव लर्निंग ज़ोन",
        text2: localStorage.getItem('glanguage')==='english'?"Animated AI Video Lessons":"एनिमेटेड एआई वीडियो पाठ",
        text3: localStorage.getItem('glanguage')==='english'?"Interactive Quizzes & Activities":"इंटरएक्टिव क्विज़ और गतिविधियाँ",
        text4: localStorage.getItem('glanguage')==='english'?"Deep Topic Breakdown":"गहन विषय विश्लेषण",
        text5: localStorage.getItem('glanguage')==='english'?"Engage Anytime, Anywhere":"किसी भी समय, कहीं भी संलग्न करें",
    },{
        text1: localStorage.getItem('glanguage')==='english'?"Live AI Doubt Solving":"लाइव एआई संदेह समाधान",
        text2: localStorage.getItem('glanguage')==='english'?"Instant Solutions to Any Query":"किसी भी प्रश्न का त्वरित समाधान",
        text3: localStorage.getItem('glanguage')==='english'?"Ask Across Subjects & Topics":"विषयों और विषयों में पूछें",
        text4: localStorage.getItem('glanguage')==='english'?"Real-Time AI-Powered Clarifications":"वास्तविक समय एआई-संचालित स्पष्टीकरण",
        text5: localStorage.getItem('glanguage')==='english'?"24/7 Expert-Like Assistance":"24/7 विशेषज्ञ जैसी सहायता",
    }];
    return (
        <section className="bg-gradient-to-b from-[#000D3F] to-black pt-10 pb-10 w-full h-full">
            <div className="w-full h-full bg-gradient-to-b from-[#000D3F] to-black">
                <div className="text-center">
                    <h1 className="text-lg lg:text-5xl font-bold">{localStorage.getItem('glanguage')==='english'?'Smarter Learning, Unlimited Possibilities':'4 अलग-अलग मोड का परिचय'}</h1>
                    <p className="my-2 text-lg">
                        {localStorage.getItem('glanguage')=='english' && <>Affordable, scalable, and accessible for all—experience AI-powered learning tailored to your needs.</>}
                        {localStorage.getItem('glanguage')=='hindi' && <>हर किसी के लिए सस्ते और स्केलेबल विकल्प। हर किसी के लिए सस्ते और स्केलेबल विकल्प। हर किसी के लिए सस्ते और स्केलेबल विकल्प।</>}
                    </p>
                    <div className="mx-auto w-11/12 lg:w-4/6 h-[70%] bg-opacity-20 bg-[#FFFFFF]  rounded-2xl mt-8">
                        <div className="grid grid-cols-2 gap-4 p-3">
                            {/* First card */}
                            <a href="/" className="block w-full sm:w-[200px] md:w-[250px] lg:w-[310px] h-[240px] sm:h-[320px] md:h-[380px] lg:h-[400px] p-2 md:p-6 bg-black rounded-2xl shadow mx-auto lg:text-lg text-[10px]">
                                <span className="bg-[#FBF7B8] text-[#573CFF] text-xs font-medium px-2.5 py-0.5 rounded-full">{localStorage.getItem('glanguage')==='english'?'Default':'डिफ़ॉल्ट'}</span>
                                <img src={img1} alt="img" className="float-right w-[46%] h-[20%] sm:h-[30%] lg:h-[30%]" />
                                <h4 className="mt-4 sm:mt-6 md:mt-8 lg:mt-9">{card[0].text1}</h4>
                                <h5 className="mt-2 sm:mt-3 md:mt-4 lg:mt-5 flex gap-2">
                                    <RiCheckboxCircleLine className="text-sm sm:text-base md:text-lg" />
                                    {card[0].text2}
                                </h5>
                                <h5 className="mt-2 sm:mt-3 md:mt-4 lg:mt-5 flex gap-2">
                                    <RiCheckboxCircleLine className="text-sm sm:text-base md:text-lg" />
                                    {card[0].text3}
                                </h5>
                                <h5 className="mt-2 sm:mt-3 md:mt-4 lg:mt-5 flex gap-2">
                                    <RiCheckboxCircleLine className="text-sm sm:text-base md:text-lg" />
                                    {card[0].text4}
                                </h5>
                                <h5 className="mt-2 sm:mt-3 md:mt-4 lg:mt-5 flex gap-2">
                                    <RiCheckboxCircleLine className="text-sm sm:text-base md:text-lg" />
                                    {card[0].text5}
                                </h5>
                               
                            </a>

                            {/* Second card */}
                            <a href="/" className="block w-full sm:w-[200px] md:w-[250px] lg:w-[310px] h-[240px] sm:h-[320px] md:h-[380px] lg:h-[400px] p-2 md:p-6 bg-black rounded-2xl shadow mx-auto lg:text-lg text-[10px]">
                                <span className="bg-[#FBF7B8] text-[#573CFF] text-xs font-medium px-2.5 py-0.5 rounded-full">{localStorage.getItem('glanguage')==='english'?'Default':'डिफ़ॉल्ट'}</span>
                                <img src={img2} alt="img" className="float-right h-[25%] sm:h-[30%] lg:h-[35%]" />
                                <h4 className="mt-4 sm:mt-6 md:mt-8 lg:mt-9">{card[1].text1}</h4>
                                <h5 className="mt-2 sm:mt-3 md:mt-4 lg:mt-5 flex gap-2">
                                    <RiCheckboxCircleLine className="text-sm sm:text-base md:text-lg" />
                                    {card[1].text2}
                                </h5>
                                <h5 className="mt-2 sm:mt-3 md:mt-4 lg:mt-5 flex gap-2">
                                    <RiCheckboxCircleLine className="text-sm sm:text-base md:text-lg" />
                                    {card[1].text3}
                                </h5>
                                <h5 className="mt-2 sm:mt-3 md:mt-4 lg:mt-5 flex gap-2">
                                    <RiCheckboxCircleLine className="text-sm sm:text-base md:text-lg" />
                                    {card[1].text4}
                                </h5>
                                <h5 className="mt-2 sm:mt-3 md:mt-4 lg:mt-5 flex gap-2">
                                    <RiCheckboxCircleLine className="text-sm sm:text-base md:text-lg" />
                                    {card[1].text5}
                                </h5>

                            </a>

                            {/* Third card */}
                            <a href="/" className="block w-full sm:w-[200px] md:w-[250px] lg:w-[310px] h-[240px] sm:h-[320px] md:h-[380px] lg:h-[400px] p-2 md:p-6 bg-black rounded-2xl shadow mx-auto lg:text-lg text-[10px]">
                                <span className="bg-[#FBF7B8] text-[#573CFF] text-xs font-medium px-2.5 py-0.5 rounded-full">{localStorage.getItem('glanguage')==='english'?'Default':'डिफ़ॉल्ट'}</span>
                                <img src={img3} alt="img" className="float-right h-[25%] sm:h-[30%] lg:h-[35%]" />
                                <h4 className="mt-4 sm:mt-6 md:mt-8 lg:mt-9">{card[2].text1}</h4>
                                <h5 className="mt-2 sm:mt-3 md:mt-4 lg:mt-5 flex gap-2">
                                    <RiCheckboxCircleLine className="text-sm sm:text-base md:text-lg" />
                                    {card[2].text2}
                                </h5>
                                <h5 className="mt-2 sm:mt-3 md:mt-4 lg:mt-5 flex gap-2">
                                    <RiCheckboxCircleLine className="text-sm sm:text-base md:text-lg" />
                                    {card[2].text3}
                                </h5>
                                <h5 className="mt-2 sm:mt-3 md:mt-4 lg:mt-5 flex gap-2">
                                    <RiCheckboxCircleLine className="text-sm sm:text-base md:text-lg" />
                                    {card[2].text4}
                                </h5>
                                <h5 className="mt-2 sm:mt-3 md:mt-4 lg:mt-5 flex gap-2">
                                    <RiCheckboxCircleLine className="text-sm sm:text-base md:text-lg" />
                                    {card[2].text5}
                                </h5>
                              
                            </a>

                            {/* Fourth card */}
                            <a href="/" className="block w-full sm:w-[200px] md:w-[250px] lg:w-[310px] h-[240px] sm:h-[320px] md:h-[380px] lg:h-[400px] p-2 md:p-6 bg-black rounded-2xl shadow mx-auto lg:text-lg text-[10px]">
                                <span className="bg-[#FBF7B8] text-[#573CFF] text-xs font-medium px-2.5 py-0.5 rounded-full">{localStorage.getItem('glanguage')==='english'?'Default':'डिफ़ॉल्ट'}</span>
                                <img src={img4} alt="img" className="float-right h-[25%] sm:h-[30%] lg:h-[35%]" />
                                <h4 className="mt-4 sm:mt-6 md:mt-8 lg:mt-9">{card[3].text1}</h4>
                                <h5 className="mt-2 sm:mt-3 md:mt-4 lg:mt-5 flex gap-2">
                                    <RiCheckboxCircleLine className="text-sm sm:text-base md:text-lg" />
                                    {card[3].text2}
                                </h5>
                                <h5 className="mt-2 sm:mt-3 md:mt-4 lg:mt-5 flex gap-2">
                                    <RiCheckboxCircleLine className="text-sm sm:text-base md:text-lg" />
                                    {card[3].text3}
                                </h5>
                                <h5 className="mt-2 sm:mt-3 md:mt-4 lg:mt-5 flex gap-2">
                                    <RiCheckboxCircleLine className="text-sm sm:text-base md:text-lg" />
                                    {card[3].text4}
                                </h5>
                                <h5 className="mt-2 sm:mt-3 md:mt-4 lg:mt-5 flex gap-2">
                                    <RiCheckboxCircleLine className="text-sm sm:text-base md:text-lg" />
                                    {card[3].text5}
                                </h5>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Page4;
