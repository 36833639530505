import React, { useEffect, useRef, useState } from 'react'
import Video_Robot from "../assets/video_robot.png"
import video from "../assets/video.mp4"
import { Circle, CircleX, CircleArrowLeft, CircleCheck } from 'lucide-react';
import { Link } from 'react-router-dom';
import MLBOOK from "../assets/MLBOOK.pdf"
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import axios from 'axios'
import startinganim from './animation1.mp4'
import video9 from './welcome.mp4'
import doubtteacher from './Doubt.mp4'
import rotate from './rotate.gif'
import doubtteacher1 from './Doubt1.mp4'
import objecter from './objects.svg'
import Loader from './Loader'
import doubtanim from './doubt_anim.mp4'
import bg_music from './bg_music.mp3'
import bg_music1 from './bg_music1.mp3'
import mcq from './mcq.mp3'
import Login from './LoginSignup2/LoginSignup2'
import SignUp from './LoginSignup2/SignUp';
import Thinking from './thinking.gif'
import './numcericals.css'
import hindiwelcome from './intro_hindi.mp4'
import DownloadNotes from './download.png';
import keyhigh from './keyhigh.mp3';  
import AskDoubts from './ask.png'; 
import Audi from './neerja.wav'
import './styles.css';
import Ending_en from './ending_en.mp4'
import Ending_hi from './ending_hi.mp4'
import thindi from './thindi.mp4'
import objecter1 from './objectsbottom.svg'
import tenglish from './tenglish.mp4'
import Blackboard from './Blackboard.png'
import { use } from 'react';
import c_h from './c_h.wav'
import k_h from './k_h.wav'
const Video = () => {
  const navigate=useNavigate()
  const [videoProgress, setVideoProgress] = useState(100);
  const[isRecognizing,setRec]=useState(false)
  const preRef =useRef(null)
  const keyhigh1 = useRef(null);
    const [videoSrc, setVideoSrc] = useState('null');
    const [isDoubt,setdoubt]=useState(false);
    const [isLoaded,setLoaded]=useState(true)
    const [showTransition, setShowTransition] = useState(false);
    const [showTransition1,setShowTransition1]=useState(false)
    const [teacher,setTeacher]=useState('null')
    const [stop,setstopper]=useState(true)
    const audioRef = useRef(null);
    const [duration,setduration]=useState(0);
    const [isTLoaded,setTLoaded]=useState(true)
    const videoRef1 = useRef(null);
    const [quizToggle, setQuizToggle] = useState(true);
    const [interview,setinterview]=useState(false)
    const [isFullScreen, setIsFullScreen] = useState(false);
    const videoRef2 = useRef(null);
    const audioRef1=useRef(null);
    const [topic,setTopic]=useState('');
    const [userdata,setuserdata]=useState({});
    const [transition, setTransition] = useState(false);
    const [capt1,setcapt1]=useState(false)
    const [dbt,setdbt]= useState(0)
    const [login,islogin]=useState(true)                         
    const [isVideoVisible, setIsVideoVisible] = useState(true);
    const [number,setnumber]=useState(1);
    const location = useLocation();
    const containerRef = useRef(null);
    const [selectedOption, setSelectedOption] = useState('');
    const [caption,setcaption]=useState('Welcome to ABIV, Lets Dive into Todays Topic and embark on an engaging and insightful journey together')
    const [l1,setl1]=useState(false)
    const [animating, setAnimating] = useState(false);
    const [capt,setcapt]=useState(0)
    const [switcher,setswitch]=useState(false)
    const [appeared,setappeared]=useState(true)
    const [hasPermission, setHasPermission] = useState(false);
    const [signup,issignup]=useState(false);
  const [error, setError] = useState(null);
  const [credits, setcredits]=useState(100);
  const [istheory,settheory]=useState(false);
  const [displayText,setDisplayText]=useState('')
  const [index,setIndex]=useState(0)
  const [text1,settext1]=useState('')
  const [language, setLanguage] = useState(false);
  const [isUserScrolling, setIsUserScrolling] = useState(false);
  const scrollTimeoutRef = useRef(null);
  const [isloaded,setloaded]=useState(true)
  const [loading,setloading]=useState(true)
  const [mobile,setmobile]=useState(false)
  const [devicew,setdevicew]=useState(false)
  const flagged  = Number(localStorage.getItem('flagged'));
  const [fullScreen1,setfullScreen1]=useState(false)
  
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < window.innerHeight) {
        if(videoRef1.current){
        localStorage.setItem('videoTime',videoRef1.current.currentTime)
        localStorage.setItem('istoggled','True')
        }
        setdevicew(true);
      } else {
        
        setdevicew(false);
   
      }
    };
  

    window.addEventListener('resize', handleResize);
    handleResize(); // Call initially to set the state based on the initial window size

    return () => {
      window.removeEventListener('resize', handleResize);
      
    };
  }, []);
  useEffect(() => {
    const intervalId = setInterval(() => {
      fetch("https://api.abiv.in/addwatchtime", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email: JSON.parse(localStorage.getItem('user-data')).email, watchtime: 10 }),
      }).then((res) => res.json()).then((data) => { console.log(data) });
    }, 10000);

    return () => clearInterval(intervalId); // Clear interval on component unmount
  }, []);

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.volume = 0.1; 
    }
  }, [audioRef.current]);
  useEffect(()=>{
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    if(window.innerWidth<window.innerHeight){setdevicew(true)}
    else {
      setdevicew(false)
    }
    if(isMobile){setmobile(true)

    }
  },[])
  useEffect(()=>{
    if(localStorage.getItem('auth-token')){
      fetch("https://api.abiv.in/fetchdata", {
        method: "POST",
        headers: {
        "Content-Type": "application/json"},
        body: JSON.stringify({email:JSON.parse(localStorage.getItem('user-data')).email})}
     ).then(response => response.json())
      .then(data => {
        setuserdata(data.data)
      })
    }
  },[])
  useEffect(()=>{
    const today = new Date(); 
      const month = today. getMonth() + 1; 
      const year = today. getFullYear(); 
      const date = today.getDate();
    localStorage.setItem('nowtime',`${date}/${month}/${year}`)
    setTimeout(()=>{
      setloading(false)
    },70000)
  },[])

  useEffect(() => {
    const handleScroll = () => {
      try{
      const pre = preRef.current;
      const isNearBottom =
        pre.scrollHeight - pre.scrollTop <= pre.clientHeight -50;
      if (isNearBottom) {
        setIsUserScrolling(false); 
      } else {
        setIsUserScrolling(true); 
        if (scrollTimeoutRef.current) clearTimeout(scrollTimeoutRef.current);
        scrollTimeoutRef.current = setTimeout(() => {
          setIsUserScrolling(false); 
        }, 1000); 
      }}catch(c){}
    };

    const pre = preRef.current;
    if (pre) pre.addEventListener("scroll", handleScroll);

    return () => {
      if (pre) pre.removeEventListener("scroll", handleScroll);
      if (scrollTimeoutRef.current) clearTimeout(scrollTimeoutRef.current);
    };
  }, []);

  useEffect(() => {
    if(preRef.current){try{
      preRef.current.scrollTop = preRef.current.scrollHeight;
    }catch(e){}}
    
  }, [displayText,isUserScrolling]);
  useEffect(() => {
    localStorage.setItem('isCheck','True')
    localStorage.setItem("isTheory",'False')
    const { lngSelected } = location.state || {};
    setLanguage(lngSelected);
  }, [location.lngSelected])
const interval=10000;
const applyTransition1 = () => {
  if (videoRef.current) {
    videoRef.current.pause();
  }
  setShowTransition1(true);
  setTimeout(() => {
    setShowTransition1(false);
    if (videoRef.current) {
      videoRef.current.play();
    }
  }, 2000); // Duration of transition
};
    const applyTransition = () => {
      if (videoRef.current) {
        videoRef.current.pause();
      }
      setShowTransition(true);

      setTimeout(() => {
        setShowTransition(false);
        if (videoRef.current) {
          videoRef.current.play();
        }
      }, 2000); // Duration of transition
    };
  const tileCount = 10;
  const xtiles = [];
  for (let i = 0; i < tileCount * tileCount; i++) {
    const row = Math.floor(i / tileCount);
    const col = i % tileCount;
    const delay = (row + col) * 50;
    xtiles.push(
      <div
        key={i}
        className={`xmosaic-tile ${showTransition1 ? "show" : ""}`}
        style={{ transitionDelay: `${delay}ms` }}
      ></div>
    );
  }
  const tiles = [];
  const centerX = Math.floor(tileCount / 2);
  const centerY = Math.floor(tileCount / 2);

  for (let i = 0; i < tileCount * tileCount; i++) {
    const row = Math.floor(i / tileCount);
    const col = i % tileCount;
    const distanceFromCenter = Math.sqrt(
      Math.pow(row - centerX, 2) + Math.pow(col - centerY, 2)
    );
    const delay = distanceFromCenter * 80; // Ripple delay
    tiles.push(
      <div
        key={i}
        className={`mosaic-tile ${showTransition ? "show" : ""}`}
        style={{ transitionDelay: `${delay}ms` }}
      ></div>
    );
  }
  const requestMicrophoneAccess = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      setHasPermission(true);
      console.log('Microphone access granted');
      // Do something with the stream, if needed
      stream.getTracks().forEach(track => track.stop()); // Stop the stream after use
    } catch (err) {
      setHasPermission(false);
      setError('Microphone access denied');
      console.error('Microphone access error:', err);
    }
  };
let durations={}
const [dura,setdura]=useState({})
    const[act,setact]=useState(false)
    const deleteFile = async (filename) => {
      try {
        const response = await axios.delete('https://api.abiv.in/delete-file', {
          data: { filename },
        });
        console.log(response.data); // 'File deleted successfully'
      } catch (error) {
        console.error('There was an error deleting the file!', error);
      }
    };
    let counter=(x)=>{
      for(let i=0;i<x.length;i++){
        switch(x[i]){
          case '1': return 1;
          case '2': return 2;
          case '3': return 3;
          case '4': return 4;
        } 
      }
    }
    const doubtupdate = async(data1)=>{
      fetch("https://api.abiv.in/doubtassing", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({data: data1 }),
          }).then((res) => res.json()).then((data) => { console.log(data) });
    }
    const updateData = async (data1) => {
      const updateUrl = 'https://api.abiv.in/submit';

      const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer YOUR_TOKEN', // Replace 'YOUR_TOKEN' with your actual token
      };
      
      try {
        // Preparing the payload
        const payload = {
          sessionid: localStorage.getItem('sessionid'),
          data: data1 // Ensure `data1` is defined in your scope
        };
      
        // Sending the POST request
       
  const response = await fetch('https://api.abiv.in/submit', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      sessionid: localStorage.getItem('sessionid'),
      data: data1,
    }),
  });

  if (response.ok) {
    const responseData = await response.json();
    console.log('Updated Data:', responseData);
  } else {
    const errorData = await response.json();
    console.warn(`Failed to update data. Status code: ${response.status}`);
    console.warn('Response:', errorData);
  }

      } catch (error) {
        // Handling errors
        console.error('Error updating data:', error);
      
        if (error.response) {
          // Server responded with a status code outside the 2xx range
          console.error('Error response data:', error.response.data);
        
        } else if (error.request) {
          // No response received
          console.error('No response received:', error.request);
          
        } else {
          // Other errors (e.g., setup issues)
          console.error('Request error:', error.message);
         
        }
      }
    }      
    const loadScript = (src) => {
      return new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.src = src;
        script.onload = () => resolve();
        script.onerror = () => reject(new Error(`Failed to load script ${src}`));
        document.body.appendChild(script);
      });
    };
    
    const Quiz = ({ onClose }) => {
      console.log(data[9][number-2]);
      console.log(data[9])
      let count=1;
      let option1,option2,option3,option4;
      try{
        option1=JSON.parse(data[9][number-2])['1']
        option2=JSON.parse(data[9][number-2])['2']
        option3=JSON.parse(data[9][number-2])['3']
        option4=JSON.parse(data[9][number-2])['4']
      }catch{
        option1="None of the above"
        option2=""
        option3=""
        option4=""
      }
      const [options, setOptions] = useState([
          { option: `${option1}`,correct:Number(JSON.parse(data[9][number-2]).correct)==1?true:false, clicked: false },
          { option: `${option2}`, correct: Number(JSON.parse(data[9][number-2]).correct)==2?true:false, clicked: false },
          { option: `${option3}`, correct: Number(JSON.parse(data[9][number-2]).correct)==3?true:false, clicked: false },
          { option: `${option4}`, correct: Number(JSON.parse(data[9][number-2]).correct)==4?true:false, clicked: false },
      ]);
  
      const handleOptionClick = (index) => {
          setOptions(prevOptions => {
              const newOptions = [...prevOptions];
              newOptions[index].clicked = !newOptions[index].clicked;
              if (newOptions[index].clicked) {
                  setSelectedOption(newOptions[index].option);
                  if(newOptions[index].correct){
                    localStorage.setItem('optioncorrect',`${newOptions[index].correct}`)
                  }
              } else {
                  setSelectedOption('');
              }
              return newOptions;
          });
      };
  
      const handleSubmit = () => {
          onClose();
      };
  
      return (
          <div className={`bg-white rounded-3xl shadow-lg w-full max-w-xl `} >
              <div className="text-center flex items-center justify-center h-[3rem] quiz_gradiant relative">
                  <CircleArrowLeft className="absolute left-[20px] top-[25%] cursor-pointer" onClick={onClose} />
                  <span className="text-white font-medium text-center">Time for some Questions</span>
              </div>
              <div className="text-left mb-4 mx-6">
                  <p className="text-lg font-semibold text-black">{JSON.parse(data[9][number-2]).question}</p>
              </div>
              <div className="space-y-4 mx-6">
                  {options.map((item, index) => (
                      <label key={index} onClick={() => handleOptionClick(index)} className="flex font-medium items-center text-black cursor-pointer">
                          {item.clicked ? (
                              item.correct ? (
                                  <CircleCheck className="text-purple-600 cursor-pointer" />
                              ) : (
                                  <CircleX className="text-purple-600 cursor-pointer" />
                              )
                          ) : (
                              <Circle className="text-purple-600 cursor-pointer" />
                          )}
                          <span className="ml-2">{item.option}</span>
                      </label>
                  ))}
              </div>
              <div className="flex justify-between mt-6">
                  <button
                      onClick={onClose}
                      className="bg-purple-500 mx-6 mb-6 text-white px-4 py-2 rounded-md hover:bg-purple-600"
                  >
                      Skip
                  </button>
                  <button
                      onClick={handleSubmit}
                      className="bg-purple-500 mx-6 mb-6 text-white px-4 py-2 rounded-md hover:bg-purple-600"
                  >
                      Submit
                  </button>
              </div>
          </div>
      );
  };
  const fullScreenMode = ()=>{
    const container=containerRef.current;
    container.requestFullscreen()
          .catch(err => console.log(`Error attempting to enable full-screen mode: ${err.message}`));
        setIsFullScreen(true);
  }
    const toggleFullScreen = () => {
      const container = containerRef.current;
      if (!document.fullscreenElement) {
        container.requestFullscreen()
          .catch(err => console.log(`Error attempting to enable full-screen mode: ${err.message}`));
        setIsFullScreen(true);
      } else {
        document.exitFullscreen()
        .then(() => setIsFullScreen(false))
        .catch(err => console.log(`Error attempting to exit full-screen mode: ${err.message}`));
      }
    };
    const handlecaption=(dict,i)=>{
      console.log(`chunk ${i}`)
      console.log(Object.keys(dict[i-1]).length)
      for(let j=1;j<=Object.keys(dict[i-1]).length;j++){
        console.log('j');
        for(let k=Number(localStorage.getItem('capt'));k<=Number(localStorage.getItem('capt'))+Math.round(dict[i-1][`C${flagged+1}D${flagged+1}xchunk_${i}_sentence_${j}`][1]);k++){
          durations[`${k}`]=dict[i-1][`C${flagged+1}D${flagged+1}xchunk_${i}_sentence_${j}`][0]
          setdura(prevDuration => ({
            ...prevDuration,
            [`${k}`]: dict[i-1][`C${flagged+1}D${flagged+1}xchunk_${i}_sentence_${j}`][0]
          }));
        }
        console.log(durations)
        setcapt(capt+Math.round(dict[i-1][`C${flagged+1}D${flagged+1}xchunk_${i}_sentence_${j}`][1]))
        localStorage.setItem('capt',`${Number(localStorage.getItem('capt'))+Math.round(dict[i-1][`C${flagged+1}D${flagged+1}xchunk_${i}_sentence_${j}`][1])}`)
      }
      console.log('ho')
      console.log(durations)
    }

    const fetchresult2=async(i,f,t)=>{
      const fetchUrl = 'https://api.abiv.in/submit1';
      const headers = {
        'Content-Type': 'application/json',
        'x-session-id': localStorage.getItem('sessionid')
        // 'Authorization': 'Bearer your-token-here',
      };
      try{
      const response1 = await axios.post(fetchUrl, {sessionid:localStorage.getItem('sessionid')}, { headers });
      if (response1.status === 200) {
        const result = response1.data;
        console.log('Received Data:', result.receivedData);
        
        let c=result.receivedData;
        c[10]=1;
        c[11]=t;
        updateData(c)
        c[0]=dbt;
        doubtupdate(c)
        // You can now use 'data' as needed
      } else {
        console.error('Failed to fetch data:', response1.statusText);
      }}catch(error){}}
    const fetchresult=async(i)=>{
      const fetchUrl = 'https://api.abiv.in/submit1';
      const headers = {
        // Add any required headers here
        'Content-Type': 'application/json',
        'x-session-id': localStorage.getItem('sessionid')
        
        // 'Authorization': 'Bearer your-token-here',
      };
      try{
      const response1 = await axios.post(fetchUrl, {sessionid:localStorage.getItem('sessionid')}, { headers });
      if (response1.status === 200) {
        const result = response1.data;
        setData(result.receivedData);
        console.log('Received Data:', result.receivedData);
        let c=result.receivedData;
        
        setTopic(c[3].split('.pdf')[0])
        // You can now use 'data' as needed
      } else {
        console.error('Failed to fetch data:', response1.statusText);
      }}catch(error){}}
      const fetchresult1=async(i)=>{
        const fetchUrl = 'https://api.abiv.in/submit1';
        const headers = {
          // Add any required headers here
          'Content-Type': 'application/json',
          'x-session-id': localStorage.getItem('sessionid')
          // 'Authorization': 'Bearer your-token-here',
        };
        try{
        const response1 = await axios.post(fetchUrl, {sessionid:localStorage.getItem('sessionid')}, { headers });
        if (response1.status === 200) {
          const result = response1.data;
          setData(result.receivedData);
          console.log('Received Data:', result.receivedData);
          let c=result.receivedData;
          if(i==2){
          handlecaption(c[12],Number(localStorage.getItem('b')))
          }
          setTopic(c[3].split('.pdf')[0])
          // You can now use 'data' as needed
        } else {
          console.error('Failed to fetch data:', response1.statusText);
        }}catch(error){}}
      
    
    const { random } =  location.sessionid || {};
    const [data,setData]=useState([0,0,0,'','',0,0,[],0,[],0,'',[],'',[],0,{'intro':'','summary':''},0,0,''])
    let b=0 

    const fetchresult3=async()=>{
      const fetchUrl = 'https://api.abiv.in/submit1';
      const headers = {
        // Add any required headers here
        'Content-Type': 'application/json',
        'x-session-id': localStorage.getItem('sessionid')
        // 'Authorization': 'Bearer your-token-here',
      };
      console.log(localStorage.getItem('sessionid'))
      const response1 = await axios.post(fetchUrl, {sessionid:localStorage.getItem('sessionid')}, { headers });
      if (response1.status === 200) {
        const result = response1.data;
        console.log('Received Data:', result.receivedData);
        let c=result.receivedData;
        setData(c);
        return c[14].length
      }else{return -1}

  }
  const fetchresult4=async(i)=>{
    const fetchUrl = 'https://api.abiv.in/submit1';
    const headers = {
      // Add any required headers here
      'Content-Type': 'application/json',
      'x-session-id': localStorage.getItem('sessionid')
      // 'Authorization': 'Bearer your-token-here',
    };
    
    
    const response1 = await axios.post(fetchUrl, {sessionid:localStorage.getItem('sessionid')}, { headers });
    if (response1.status === 200) {
      const result = response1.data;
      console.log('Received Data:', result.receivedData);
      let c=result.receivedData;
      return c[14][i]
    }else{return -1}

}
    const fetchVideo = async (i) => {
        
      try {
        console.log(`https://api.abiv.in/downloads/uploads/${localStorage.getItem('sessionid')}_C${Number(flagged)+1}D${Number(flagged)+1}xconcatenated_chunk_${i}.mp4`)
        const response = await fetch(`https://api.abiv.in/downloads/uploads/${localStorage.getItem('sessionid')}_C${Number(flagged)+1}D${Number(flagged)+1}xconcatenated_chunk_${i}.mp4`);
        if (!response.ok) {
          throw new Error('Failed to fetch video');
        }
        const videoBlob = await response.blob();
        const videoUrl = URL.createObjectURL(videoBlob);
        setVideoSrc(videoUrl);
        localStorage.setItem('animation', videoUrl);
        console.log(JSON.stringify(localStorage.getItem('animation')))
        setLoaded(false)
      } catch (error) {
        console.error(error);
      }
    };
    const fetchTeacher = async (i) => {
      try {
        console.log(`https://api.abiv.in/downloads/uploads/${localStorage.getItem('sessionid')}_C${Number(flagged)+1}D${Number(flagged)+1}xconcatenated_chunk_${i}.mp4`)
        const response = await fetch(`https://api.abiv.in/downloads/uploads/${localStorage.getItem('sessionid')}_C${Number(flagged)+1}D${Number(flagged)+1}xresult_video_${i}.mp4`); // Change the URL according to your server
        if (!response.ok) {
          throw new Error('Failed to fetch video');
        }
        const videoBlob = await response.blob();
        const videoUrl = URL.createObjectURL(videoBlob);
        setTeacher(videoUrl);
        localStorage.setItem('teacher', videoUrl);
        setTLoaded(false)
      } catch (error) {
        console.error(error);
      }
    };
    useEffect(() => {
      localStorage.setItem('index',0)

      setTimeout(()=>{
        if(!localStorage.getItem('auth-token')){islogin(false);
        videoRef1.current.pause();
        videoRef2.current.pause();
        }
        },10000)
      requestMicrophoneAccess()
      localStorage.setItem('capt','0');
      localStorage.setItem('capt1','0');
      localStorage.setItem('duration','0');
      localStorage.setItem('appeared','0');
      localStorage.setItem('mcq','0');
      localStorage.setItem('mcqcorrect','0');
      localStorage.setItem('optioncorrect','false');
      localStorage.setItem('mcqtotal','0');
      loadScript('https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js')
      .then(() => {
        console.log('jQuery loaded successfully');
      })
      .catch((error) => {
        console.error(error.message);
      });
      console.log(data)
      localStorage.setItem('isloading','True');
      localStorage.setItem('b', JSON.stringify(0));
      localStorage.setItem('animation', null);
      localStorage.setItem('teacher', null);

    
    }, []); // Empty dependency array means this effect runs only once on mount
  
    const [showQuiz, setShowQuiz] = useState(false);
    const [quizInterval, setQuizInterval] = useState(10); 
    const videoRef = useRef(null);
    const [isPlaying, setIsPlaying] = useState(false);
    
    useEffect(() => {
      console.log(language)
      localStorage.setItem('text1','')
      if (videoRef1.current) {
        // Set the volume to 50% (0.5)
        videoRef1.current.volume = 0.2;
      }
      const fullscreenBtn = document.getElementById('fullscreen-toggle-btn');
      if (fullscreenBtn) {
        fullscreenBtn.addEventListener('click', toggleFullScreen);
      }
      return () => {
        if (fullscreenBtn) {
          fullscreenBtn.removeEventListener('click', toggleFullScreen);
        }
      };

    }, [quizInterval]);
    useEffect(() => {
      const checker=setInterval(async() => {
        const fetchUrl='https://api.abiv.in/submit1'
        const headers = {
          // Add any required headers here
          'Content-Type': 'application/json',
          'x-session-id': localStorage.getItem('sessionid')
          // 'Authorization': 'Bearer your-token-here',
        };
        const response1 = await axios.post(fetchUrl, {sessionid:localStorage.getItem('sessionid')}, { headers });
    if (response1.status === 200) {
      const result = response1.data;
      console.log('Received Data:', result.receivedData);
      if(result.receivedData[17]==1){
        navigate('/')
        alert('Numerical data found, Please use the Numerical Section')
        clearInterval(checker)
      }
      if(result.receivedData[18]==1){
        navigate('/')
        alert('NSFW content found, Access Denied')
        clearInterval(checker)

      }
    }
      },2000)
    },[]);
    const closeQuiz = async() => {
        if(localStorage.getItem('optioncorrect')=='true')
          localStorage.setItem('mcqcorrect',`${Number(localStorage.getItem('mcqcorrect'))+1}`)
        setShowQuiz(false);
        audioRef1.current.pause()
        setQuizInterval(prev => prev + 10); 
        localStorage.setItem('mcq','0');
        localStorage.setItem('b', JSON.stringify(Number(localStorage.getItem('b'))+1));
        checkVideoAvailability(Number(localStorage.getItem('b')))

    };
    const handlePlayPause = () => {
      setIsPlaying(prev => !prev); 
      if (isPlaying) {
        videoRef1.current.pause(); 
        videoRef2.current.pause();
        audioRef.current.pause();
        audioRef.current.volume=0.1
      } else {
        videoRef2.current.play(); 
        videoRef1.current.play();
        audioRef.current.volume=0.1
        audioRef.current.play();
      }
    };
    const handleSliderChange = (e) => {
      const video = videoRef2.current;
      const newTime = (e.target.value / 100) * video.duration;
      video.currentTime = newTime;
      videoRef1.current.currentTime=newTime;
      setVideoProgress(e.target.value);
  };

  const handleTimeUpdate = () => {
    if(videoProgress!=100){
      const video = videoRef2.current;
      const progress = (video.currentTime / video.duration) * 100;
      setVideoProgress(progress);
    }
  };
    const preloadVideo = (url) => {
      return new Promise((resolve, reject) => {
        const video = document.createElement('video');
        video.src = url;
        video.oncanplaythrough = () => resolve(video);
        video.onerror = (e) => reject(e);
      });
    };
    const checkVideoAvailability = async (i) => {
console.log('called at instance'+i)
console.log('Duration is '+localStorage.getItem('duration'));

      try {
        await fetchresult(2)
        const response = await axios.get(`https://api.abiv.in/check-availability/${localStorage.getItem('sessionid')}_C${Number(flagged)+1}D${Number(flagged)+1}xconcatenated_chunk_${i}.mp4`);
        const response1=await axios.get(`https://api.abiv.in/check-availability/${localStorage.getItem('sessionid')}_C${Number(flagged)+1}D${Number(flagged)+1}xresult_video_${i}.mp4`)
        if (response.data.available && response1.data.available) {
          let ca=Number(localStorage.getItem('capt'))
          let ca2=Number(localStorage.getItem('capt1'))
          console.log('my value is'+ca)
          await fetchresult1(2)
setl1(true)
          setLoaded(true)
          let url1=`https://api.abiv.in/downloads/uploads/${localStorage.getItem('sessionid')}_C${Number(flagged)+1}D${Number(flagged)+1}xconcatenated_chunk_${i}.mp4`
          let url2=`https://api.abiv.in/downloads/uploads/${localStorage.getItem('sessionid')}_C${Number(flagged)+1}D${Number(flagged)+1}xresult_video_${i}.mp4`
          videoRef1.current.src=url1
          videoRef2.current.src=url2
          videoRef1.current.currentTime=ca2
          videoRef2.current.currentTime=ca2
          localStorage.setItem('coins',`${Number(localStorage.getItem('coins'))-3}`)
        
          videoRef1.current.load()
          videoRef2.current.load()
          localStorage.setItem('ruko','False');
          if(localStorage.getItem('toskip')){
            videoRef1.current.currentTime=localStorage.getItem('videoDuration')
            videoRef2.current.currentTime=localStorage.getItem('videoDuration')
            localStorage.removeItem('toskip')
          }
        
          if(login){
         
       
          audioRef.current.play()
          }
          videoRef1.current.oncanplaythrough = () => {setl1(false);
            console.log(Number(localStorage.getItem('duration')))
            if(!capt1){
            }else{setcapt1(false)
            }
            
            setact(true)
            setTransition(false)
            
          }
          videoRef2.current.oncanplaythrough = () => {setl1(false);     
          }
        videoRef1.current.muted=true
        videoRef2.current.muted=false
      
        } else {
          setLoaded(false)
          setTimeout(()=>{checkVideoAvailability(i)}, 5000); // Retry after 5 seconds if not available
        }
      } catch (error) {
        console.error('Error checking video availability:', error);
        setLoaded(false)
        setTimeout(()=>{checkVideoAvailability(i)}, 5000); // Retry after 5 seconds if there's an error
      }
    };
    const checkDoubtAvailability = async (i) => {
      try {
        fetchresult(1)
        const response = await axios.get(`https://api.abiv.in/check-availability/${localStorage.getItem('sessionid')}_${dbt}C${Number(flagged)+1}D${Number(flagged)+1}xdoubtconcatenated_chunk_${i}.mp4`);
        if (response.data.available) {
          setl1(true)
          setLoaded(true)
          setdoubt(true)
          let url1=`https://api.abiv.in/downloads/uploads/${localStorage.getItem('sessionid')}_${dbt}C${Number(flagged)+1}D${Number(flagged)+1}xdoubtconcatenated_chunk_${i}.mp4`
          await preloadVideo(url1);
          setAnimating(true);
          videoRef1.current.src=url1
          videoRef1.current.load()
          videoRef2.current.load()
          videoRef1.current.oncanplaythrough = () => setl1(false);
          setIsVideoVisible(false)
          setAnimating(false);
          setdbt(dbt+1);
          if(localStorage.getItem('auth-token')){
            try{
          if(!userdata[localStorage.getItem('sessionid')].doubt){
          userdata[localStorage.getItem('sessionid')].doubt=[]
          }else{
            userdata[localStorage.getItem('sessionid')].doubt.push({
              "coins":3,
              "duration":videoRef1.current.duration,
              "index":i
            }
          )
          userdata.coins=userdata.coins-3
          fetch("https://api.abiv.in/updatedb", {
            method: "POST",
            headers: {
            "Content-Type": "application/json"},
            body: JSON.stringify(userdata)
          })
          }}catch(e){
            console.log('error')
          }
       
        audioRef.current.play()
        videoRef1.current.muted=false
        videoRef2.current.muted=true
        } else {
          setLoaded(false)
          setTimeout(()=>{checkDoubtAvailability(i)}, 5000); 
        }
      } }catch (error) {
        console.error('Error checking video availability:', error);
        setLoaded(false)
        setTimeout(()=>{checkDoubtAvailability(i)}, 5000);
      }
    };
    const Endgame=async()=>{
      if(localStorage.getItem('vending') && localStorage.getItem('vending')==='1')
        {
          window.location.href='/mcq'
          return;
        }
      if(Number(localStorage.getItem('b'))!=0 && Number(localStorage.getItem('b'))==data[6]){
        videoRef2.current.src=localStorage.getItem('language').includes('False')?Ending_en:Ending_hi
        videoRef1.current.src=startinganim
        videoRef1.current.load()
        videoRef2.current.load()
        videoRef1.current.play()
        videoRef2.current.play()
        localStorage.setItem('vending','1')
        
      }
     
      setact(false)
      console.log(data[6])
      if(videoRef2.current.muted){
      }
      if(videoRef1.current.src.includes('doubt_anim')){
        setcaption('If you have any doubts feel free to ask')
        setstopper(false)
        const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
        const recognition = new SpeechRecognition();
        recognition.continuous = true;
        recognition.lang = 'en-US';
       
recognition.onstart = function() {
  setRec(true)
    console.log("Recognition started.");
};


    recognition.onerror = function(event) {
        console.error('Recognition error:', event.error);
        // Restart recognition unless there's a specific error that should stop it
        if (isRecognizing) {
          recognition.stop();  // Stop recognition if it's still running
      }
      setTimeout(()=>{recognition.start()},1000)
      
        
    };
        recognition.onresult = function(event) {
          setRec(false)
          const result = event.results[event.results.length - 1];
          const transcript = result[0].transcript.trim();
          console.log("transcript")
          console.log(transcript);
          console.log("transcript:", transcript);
          recognition.stop();
          if(transcript.includes('no')){
            localStorage.setItem('mcq','0');
            localStorage.setItem('b',Number(localStorage.getItem('b'))+1)
            checkVideoAvailability(Number(localStorage.getItem('b')))
            setcapt1(true)
            setact(true)
            setstopper(true)
          }else{
          fetchresult2(2,flagged,transcript)
          console.log(capt1)
          checkDoubtAvailability(1)
          }
          console.log('b value is'+localStorage.getItonCloseem('b'))
        };
      
        recognition.start();
      }
      else if(1==1 ){
        setnumber(number+1)
        setdoubt(false)
        setIsVideoVisible(true)
        let c=b+1;
        localStorage.setItem('b',JSON.parse(Number(localStorage.getItem('b')))+1)
        console.log('b value is'+localStorage.getItem('b'))
        b=b+1
        console.log(b)
        console.log(JSON.parse(localStorage.getItem('b')))
        console.log('i was called')
         if(localStorage.getItem('b')==1){
        checkVideoAvailability(1);
        setcapt1(false)
       }else{
        setstopper(true)
        if(Number(localStorage.getItem('b'))%3==0 && quizToggle){
          localStorage.setItem('appeared','1')
          localStorage.setItem('b',JSON.parse(Number(localStorage.getItem('b')))-1)
          setcapt1(true)
          if(Number(localStorage.getItem('mcq'))==0){
          setcaption('If you have any doubts feel free to ask')
          setShowQuiz(true)
          setnumber(number+1)
          localStorage.setItem('mcq','1');
          localStorage.setItem('mcqtotal',Number(localStorage.getItem('mcqtotal'))+1)
          audioRef1.current.play()
          }else{
            videoRef1.current.src=doubtanim
            videoRef2.current.src=doubtteacher
            videoRef1.current.load()
            videoRef2.current.load()
            videoRef2.current.muted=false;
          }
         setcaption('If you have any doubts, feel free to ask !')
      }else{
        localStorage.setItem('appeared','0')
        console.log('avail'+Number(localStorage.getItem('duration')))

        setact(true)
       
        
checkVideoAvailability(Number(localStorage.getItem('b')))
}}      
    }
    }
    const toggleQuiz = () => {
      setQuizToggle(!quizToggle);
      if (!quizToggle) {
          setShowQuiz(false);
      }
  };
    const downloadNotes = () => {
      var printWindow = window.open('', '', 'height=400,width=800');
      
         // printWindow.document.write(`</head><body ><title>${data[3]}</title>`);
          //printWindow.document.write(`</body><b>Summary</b></html><br><br>`);
          //printWindow.document.write(`</body>${data[3]}</html><br><br>`);
          //printWindow.document.write(`</body><b>Multiple Choice Questions</b></html><br><br>`);
          //printWindow.document.write(`</body>${data[flagged][9].join('<br><br>')}</html>`);
          printWindow.document.write(`
           <!DOCTYPE html>
<html>
  <head>
  <style>
  @import url("https://cdnjs.cloudflare.com/ajax/libs/meyer-reset/2.0/reset.min.css");
* {
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
}
html,
body {
  margin: 0px;
  height: 100%;
}
/* a blue color as a generic focus style */
button:focus-visible {
  outline: 2px solid #4a90e2 !important;
  outline: -webkit-focus-ring-color auto 5px !important;
}
a {
  text-decoration: none;
}
  .notes {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.notes .overlap-group-wrapper {
  background-color: #ffffff;
  overflow: hidden;
  width: 819px;
  height: 2229px;
}

.notes .overlap-group {
  position: relative;
  width: 822px;
  height: 2348px;
}

.notes .frame {
  display: flex;
  flex-direction: column;
  width: 142px;
  align-items: center;
  position: absolute;
  top: 29px;
  left: 31px;
  opacity: 0.72;
}

.notes .group {
  position: relative;
  width: 142px;
  height: 71px;
  background-image: url(./img/image-1-2.png);
  background-size: 100% 100%;
}

.notes .BG {
  position: absolute;
  width: 822px;
  height: 2348px;
  top: 0;
  left: 0;
  background: linear-gradient(
    180deg,
    rgba(1, 2, 25, 0.9) 0%,
    rgba(2.04, 10.28, 102, 0.9) 100%
  );
}

.notes .div {
  display: flex;
  flex-direction: column;
  width: 760px;
  align-items: flex-start;
  gap: 364px;
  position: absolute;
  top: 370px;
  left: 29px;
}

.notes .watermark {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  align-self: stretch;
  width: 100%;
  flex: 0 0 auto;
  opacity: 0.03;
}

.notes .image-wrapper {
  position: relative;
  width: 760.03px;
  height: 397.49px;
}

.notes .image {
  position: absolute;
  width: 760px;
  height: 380px;
  top: 17px;
  left: 0;
}

.notes .text-wrapper {
  position: relative;
  align-self: stretch;
  margin-top: -42.82px;
  font-family: "Instrument Sans-Medium", Helvetica;
  font-weight: 500;
  color: #ffffff;
  font-size: 107px;
  text-align: center;
  letter-spacing: 0;
  line-height: normal;
}

.notes .objects {
  position: absolute;
  width: 281px;
  height: 768px;
  top: 0;
  left: 538px;
}

.notes .img {
  position: absolute;
  width: 282px;
  height: 1000px;
  top: 1229px;
  left: 0;
}

.notes .frame-wrapper {
  display: flex;
  flex-direction: column;
  width: 660px;
  align-items: flex-start;
  justify-content: center;
  gap: 35px;
  position: absolute;
  top: 103px;
  left: 79px;
}

.notes .frame-2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 25.19px;
  position: relative;
  align-self: stretch;
  width: 100%;
  flex: 0 0 auto;
}

.notes .frame-3 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 18px;
  position: relative;
  align-self: stretch;
  width: 100%;
  flex: 0 0 auto;
}

.notes .text-wrapper-2 {
  width: fit-content;
  font-size: 70px;
  position: relative;
  margin-top: -1.26px;
  font-family: "Inter-SemiBold", Helvetica;
  font-weight: 600;
  color: #ffffff;
  letter-spacing: 0;
  line-height: normal;
}

.notes .text-wrapper-3 {
  position: relative;
  align-self: stretch;
  font-family: "Inter-Regular", Helvetica;
  font-weight: 400;
  color: #ffffff;
  font-size: 20.2px;
  text-align: right;
  letter-spacing: 0;
  line-height: normal;
}

.notes .frame-4 {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40.31px;
  position: relative;
  align-self: stretch;
  width: 100%;
  flex: 0 0 auto;
}

.notes .frame-5 {
  display: flex;
  flex-direction: column;
  width: 653.7px;
  align-items: flex-start;
  gap: 15.11px;
  position: relative;
  flex: 0 0 auto;
}

.notes .text-wrapper-4 {
  position: relative;
  align-self: stretch;
  margin-top: -1.26px;
  font-family: "Inter-SemiBold", Helvetica;
  font-weight: 600;
  color: #ffffff;
  font-size: 25.2px;
  letter-spacing: 0;
  line-height: normal;
}

.notes .korem-ipsum-dolor {
  position: relative;
  align-self: stretch;
  font-family: "Inter-Regular", Helvetica;
  font-weight: 400;
  color: #ffffff;
  font-size: 20px;
  letter-spacing: 0;
  line-height: normal;
}

.notes .frame-6 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  position: relative;
  align-self: stretch;
  width: 100%;
  flex: 0 0 auto;
}

.notes .frame-7 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12.6px;
  position: relative;
  align-self: stretch;
  width: 100%;
  flex: 0 0 auto;
}

.notes .frame-8 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 15.11px;
  padding: 15.11px;
  position: relative;
  align-self: stretch;
  width: 100%;
  flex: 0 0 auto;
  border-radius: 13.85px;
  border: 1.26px solid;
  border-color: #d9d9d9;
}

.notes .p {
  position: relative;
  align-self: stretch;
  margin-top: -1.26px;
  font-family: "Inter-Medium", Helvetica;
  font-weight: 500;
  color: #b4b4b4;
  font-size: 20px;
  letter-spacing: 0;
  line-height: normal;
}

.notes .frame-9 {
  display: flex;
  flex-direction: column;
  width: 181.37px;
  align-items: flex-start;
  gap: 10.08px;
  position: relative;
  flex: 0 0 auto;
}

.notes .frame-10 {
  display: flex;
  align-items: flex-end;
  gap: 7.56px;
  position: relative;
  align-self: stretch;
  width: 100%;
  flex: 0 0 auto;
}

.notes .ellipse {
  position: relative;
  width: 25.19px;
  height: 25.19px;
  border-radius: 12.6px;
  border: 1.26px solid;
  border-color: #b4b4b4;
}

.notes .text-wrapper-5 {
  position: relative;
  flex: 1;
  margin-top: -0.07px;
  font-family: "Inter-Medium", Helvetica;
  font-weight: 500;
  color: #b4b4b4;
  font-size: 20px;
  letter-spacing: 0;
  line-height: normal;
}

.notes .frame-11 {
  display: flex;
  align-items: center;
  gap: 7.56px;
  position: relative;
  align-self: stretch;
  width: 100%;
  flex: 0 0 auto;
}

.notes .vector {
  position: relative;
  width: 25.19px;
  height: 25.19px;
}

.notes .text-wrapper-6 {
  position: relative;
  flex: 1;
  margin-top: -0.66px;
  font-family: "Inter-Medium", Helvetica;
  font-weight: 500;
  color: #b4b4b4;
  font-size: 20px;
  letter-spacing: 0;
  line-height: normal;
}

.notes .frame-12 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 12.6px;
  padding: 12.6px;
  position: relative;
  align-self: stretch;
  width: 100%;
  flex: 0 0 auto;
}

.notes .text-wrapper-7 {
  position: relative;
  align-self: stretch;
  font-family: "Inter-Medium", Helvetica;
  font-weight: 500;
  color: #b4b4b4;
  font-size: 20px;
  letter-spacing: 0;
  line-height: normal;
}

.notes .text-wrapper-8 {
  position: absolute;
  top: 2195px;
  left: 355px;
  font-family: "Inter-Medium", Helvetica;
  font-weight: 500;
  color: #6571ff;
  font-size: 14px;
  letter-spacing: 0;
  line-height: normal;
}

  </style>
    <meta charset="utf-8" />
  </head>
  <body>
    <div class="notes">
      <div class="overlap-group-wrapper">
        <div class="overlap-group">
          <div class="frame"><div class="group"></div></div>
          <div class="BG"></div>
          <div class="div">
            <div class="watermark">
              <div class="image-wrapper"><img class="image" src="img/image-1.png" /></div>
              <div class="text-wrapper">www.abiv.in</div>
            </div>
            <div class="watermark">
              <div class="image-wrapper"><img class="image" src="img/image.png" /></div>
              <div class="text-wrapper">www.abiv.in</div>
            </div>
          </div>
          <img class="objects" src="${objecter}" />
          <img class="img" src="${objecter1}" />
          <div class="frame-wrapper">
            <div class="frame-2">
              <div class="frame-3">
                <div class="text-wrapper-2">${data[3]}</div>
                <div class="text-wrapper-3">Date : ${localStorage.getItem('nowtime')}</div>
              </div>
              <div class="frame-4">
                <div class="frame-5">
                  <div class="text-wrapper-4">Introduction</div>
                  <p class="korem-ipsum-dolor">
                   ${data[16].intro?data[16].intro:'No Introduction'}
                  </p>
                </div>
                <div class="frame-5">
                  <div class="text-wrapper-4">Topic Summary</div>
                  <p class="korem-ipsum-dolor">
                   ${data[16].summary?data[16].summary:'No Summary'}
                  </p>
                  
                </div>
                <div class="frame-5">
                  <div class="text-wrapper-4">Topic Key points</div>
                  <p class="korem-ipsum-dolor">
                   ${
                   data[14].map((item,index)=>{
                      return `<p class="korem-ipsum-dolor">${item}</p>`
                    })
                   }
                  </p>
                </div>
                <div class="frame-6">
                  <div class="text-wrapper-4">Important MCQs</div>
                  <div class="frame-7">
                  ${data[9].map((item,index)=>{
                    return `<div class="frame-8">
                      <p class="p">${JSON.parse(item).question}</p>
                      <div class="frame-9">
                        <div class="frame-10">
                          <div class="ellipse"></div>
                          <div class="text-wrapper-5">${JSON.parse(item)['1']}</div>
                        </div>
                        <div class="frame-11">
                          <img class="vector" src="img/vector-3.svg" />
                          <div class="text-wrapper-6">${JSON.parse(item)['2']}</div>
                        </div>
                        <div class="frame-11">
                          <div class="ellipse"></div>
                          <div class="text-wrapper-6">${JSON.parse(item)['3']}</div>
                        </div>
                        <div class="frame-11">
                          <div class="ellipse"></div>
                          <div class="text-wrapper-6">${JSON.parse(item)['4']}</div>
                        </div>
                      </div>
                    </div>`
                  })}
                    
                   
                  </div>
                </div>
               
              </div>
            </div>
          </div>
          <div class="text-wrapper-8">Generated by AI</div>
        </div>
      </div>
    </div>
  </body>
</html>
 
            `)
      printWindow.document.close();
      printWindow.print();
        //const link = document.createElement('a');
        //link.href = MLBOOK; 
        //link.download = 'notes.pdf';
        //document.body.appendChild(link);
        //link.click();
        //document.body.removeChild(link);
    };
    const applying = async () => {
      localStorage.setItem('stater','False')
      localStorage.setItem('ruko','True');
      if (localStorage.getItem('skip-off')) {
        checkVideoAvailability(Number(localStorage.getItem('b')));
        return;
      }

      if (Number(localStorage.getItem('b')) !== 0) {
        localStorage.setItem('capt1', Math.round(videoRef2.current.duration));
        setTimeout(() => {
          keyhigh1.current.play();
        }, 3000);
      }

      const checkResult = async () => {
        while (true) {
          const resultLength = await fetchresult3();
          if (resultLength >= Number(localStorage.getItem('b')) + 1) {
            break; // Exit loop when condition is met
          }
          await new Promise(resolve => setTimeout(resolve, 1000)); // Wait for 1 second before checking again
        }
      
        localStorage.setItem('text1', ' ' + await fetchresult4(localStorage.getItem('b')));
        localStorage.setItem('isTheory', 'True');
      
        await displayTextWithDelay(); // Start displaying text
      };
      
      const displayTextWithDelay = async () => {
        let currentIndex = Number(localStorage.getItem('index'));
        const text1 = localStorage.getItem('text1');
      
        while (currentIndex < text1.length - 1 && localStorage.getItem('isTheory').includes('True')) {
          setDisplayText(prevDisplayText => prevDisplayText + text1[currentIndex]);
      
          if (!isUserScrolling) {
            try {
              preRef.current.scrollTop = preRef.current.scrollHeight;
            } catch (e) {
              console.error(e);
            }
          }
      
          currentIndex++;
          localStorage.setItem('index', currentIndex);
          await new Promise(resolve => setTimeout(resolve, 120)); // Simulate the interval
        }
      
        localStorage.setItem('isTheory', 'False');
       
        localStorage.setItem('index', 0);
      
        const b = localStorage.getItem('b');
        setTimeout(() => {
          if (b === localStorage.getItem('b')){
            settheory(false);
            setDisplayText('');}},8000);
        setTimeout(() => {
          if (b === localStorage.getItem('b')){
            settheory(false);
            setDisplayText('');
            Endgame();}
        }, 10000);
      };
            checkResult();
      
    };
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        console.log("escaped");
        setIsFullScreen(false);
        if (document.fullscreenElement) {
          document.exitFullscreen();
        }
      }
      console.log(event.key);
    };

    document.addEventListener('fullscreenchange', () => {
      if (!document.fullscreenElement) {
        setIsFullScreen(false);
      }
    });

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
      document.removeEventListener('fullscreenchange', () => {
        if (!document.fullscreenElement) {
          setIsFullScreen(false);
        }
      });
    };
  }, []);


    return (
        <div className='video-page-background w-screen  flex flex-col justify-center items-center text-white font-[Montserrat] '>
            <h1 className='md:text-5xl text-[22px] text-center lg:text-justify  mt-5 font-semibold font-[jost]'>{topic==''?``:`TOPIC NAME: ${topic}`}</h1>
            <div className='relative flex' style={{marginLeft: '60%',marginBottom:'10px'}}>
                        <span className='text-sm mr-2'>Quiz:</span>
                        <label className="relative inline-flex items-center cursor-pointer">
                            <input type="checkbox" className="sr-only peer" checked={quizToggle} onChange={toggleQuiz} />
                            <div className="w-11 h-6 bg-gray-200 rounded-full peer dark:bg-gray-700 peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                            <span className="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">{quizToggle ? 'On' : 'Off'}</span>
                        </label>
                    </div>
                <div ref={containerRef} >
                  {devicew &&
                   <div 
                   className={`flex object-cover relative ${isFullScreen ? 'h-full w-full' : 'h-[30vh] w-[70vw]'}`} style={{backgroundColor:'grey'}}>
                    <div className='w-[80vh] h-[30vh] center'>
              <img src={rotate} style={{marginTop: '15%', marginLeft: '28%'}} alt="Rotate"/>
</div>
                   </div>
                  }
               {(!localStorage.getItem('isTheory').includes('True') && !localStorage.getItem('isDoubt').includes('True')) &&  !loading && !devicew &&
              <div 
          className={`flex object-cover relative ${isFullScreen ? 'h-full w-full' : 'h-[50vh] w-[90vw] md:h-[60vh] md:w-[80vw] lg:h-[520px] lg:w-[1000px]'}`} 
              style={{
                border: '2px solid #fff',
                borderRadius: '15px',
                padding: '3px',
              }}
            >
               

      {/* Video 1 */}
      <div className="flex flex-wrap object-cover h-full w-full justify-center items-center " style={{ backgroundColor: '#181c64' }}>

      <div
    className={`${showQuiz  ? "blur-sm" : ""} 
                ${!isLoaded || l1 ? "blur-sm" : ""} 
                ${isVideoVisible ? "w-1/2" : "w-1/2"} 
                h-full flex justify-center items-center`}
  >      <div className="caption absolute object-cover bottom-4 left-4 text-white bg-black bg-opacity-50 px-2 py-1 rounded-md z-20">

          {caption}
        </div>
        <div className={`mosaic-container object-cover w-full ${isFullScreen ? 'h-full' : 'h-full'}`}>
        <video
          ref={videoRef1}
          onClick={handlePlayPause}
          className="object-cover w-full h-full"
          onWaiting={() => {if (videoRef2.current) videoRef2.current.pause()}}
          onPlaying={()=>{if (videoRef2.current){if(localStorage.getItem('istoggled').includes('True')){
            videoRef2.current.currentTime=localStorage.getItem('videoTime')
            localStorage.setItem('istoggled','False')
          }
          if(localStorage.getItem('ruko') && localStorage.getItem('ruko').includes('False')){videoRef2.current.play();}videoRef1.current.muted=true
          }}}
          onCanPlay={()=>{if(videoRef1.current)videoRef1.current.play()
            if(videoRef2.current)if(localStorage.getItem('ruko') && localStorage.getItem('ruko').includes('False'))videoRef2.current.play()
          }}
          onTransitionEnd={() => setAnimating(false)}
          style={{transition: 'transform 1s',marginLeft:'10px' }}
          onTimeUpdate={() => {
            if (Math.floor(videoRef2.current.duration - videoRef2.current.currentTime) === 3) {
              setTransition(true);
            }
            audioRef.current.volume = 0.1;
            if(act && (Number(localStorage.getItem('b'))!=0 && stop &&!videoRef1.current.src.includes('doubt_anim')) )
            if(caption!=dura[`${Math.round(videoRef2.current.currentTime)}`]){
              let transitions=[applyTransition,applyTransition1]
              let randomIndex = Math.floor(Math.random() * transitions.length);
              transitions[randomIndex]();
            }
            setcaption(dura[`${Math.round(videoRef2.current.currentTime)}`]);
          }}
          controls
        >
          <source src={localStorage.getItem('stater').includes('True')?startinganim:`https://api.abiv.in/downloads/uploads/${localStorage.getItem('sessionid')}_C${Number(flagged)+1}D${Number(flagged)+1}xconcatenated_chunk_${Number(localStorage.getItem('b'))}.mp4`} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div className={`tiles ${showTransition ? "visible" : ""}`}>{tiles}</div>
        <div className={`xtiles ${showTransition1 ? "visible" : ""}`}>{xtiles}</div>

        </div>
      </div>

      {/* Video 2 */}
      
      <div
    className={`items-end ${isVideoVisible ? 'w-1/2' : 'w-1/2'} 
                h-full flex justify-center ${showQuiz ? "blur-sm" : ""} 
                ${!isLoaded || l1 ? "blur-sm" : ""}`}
                
  >
    {Number(localStorage.getItem('b'))==0 && 
<button style={{position:'absolute',bottom:'9vh',right:'2vh',backgroundColor:'black',color:'white',borderRadius:'10px',padding:'5px'}} onClick={()=>{
  videoRef1.current.currentTime=videoRef1.current.duration-1
  videoRef2.current.currentTime=videoRef2.current.duration-1
}}>
                  Skip Intro
                </button>}
    <video
          ref={videoRef2}
          onTimeUpdate={handleTimeUpdate}
          onEnded={applying}
          onWaiting={() => {if (videoRef1.current) videoRef1.current.pause()}}
          onPlaying={()=>{if (videoRef1.current) {if(localStorage.getItem('istoggled').includes('True')){
            videoRef1.current.currentTime=localStorage.getItem('videoTime')
            localStorage.setItem('istoggled','False')
          }
            if(localStorage.getItem('ruko') && localStorage.getItem('ruko').includes('False')){
            videoRef1.current.play()}}}}
          controls
          className="object-cover w-full h-full flex justify-end"
          style={{ backgroundColor: '#086c7c', display: isVideoVisible ? 'block' : 'none' }}
        >
          <source src={localStorage.getItem('stater').includes('True') ? (localStorage.getItem('language').includes('False') ? video9 : hindiwelcome) : `https://api.abiv.in/downloads/uploads/${localStorage.getItem('sessionid')}_C${Number(flagged)+1}D${Number(flagged)+1}xresult_video_${Number(localStorage.getItem('b'))}.mp4`} type="video/mp4" />
          Your browser does not support the video tag.
        </video>

        {/* Loader */}
        
      </div>
      {!isLoaded || l1 ? (
          <div className={`absolute inset-0 flex items-center justify-center z-10 ${!isLoaded || l1 ?"":"blur-sm"}`}>
            <img src="https://i.giphy.com/6ae7c7HIAkRkEnkoAc.webp" className="loadingimg" alt="Loading..." />
            <p className='font-semibold font-[jost]'>Thinking...</p>
            <audio autoPlay src={bg_music} loop></audio>
          </div>
         
        ) : null}
      {/* Quiz and controls */}
      {showQuiz && (
        <div className="absolute inset-0 flex items-center justify-center z-30">
          <Quiz onClose={closeQuiz}/>
        </div>)
        }
        
      <div className="absolute bottom-0 left-0 right-0 p-4 bg-black bg-opacity-50 flex items-center justify-between opacity-0 hover:opacity-100 transition-opacity duration-300 "
      onMouseEnter={() => {
        const captions = document.querySelectorAll('.caption');
        captions.forEach(caption => caption.style.bottom = '60px');
    }}
    style={{zIndex:'4'}}
    onMouseLeave={() => {
        const captions = document.querySelectorAll('.caption');
        captions.forEach(caption => caption.style.bottom = '12px');
    }}
      >
        <button onClick={handlePlayPause} className="text-white">
          {isPlaying ? 'Pause' : 'Play'}
        </button>
        <input
          type="range"
          min="0"
          max="100"
          value={videoProgress}
          onChange={handleSliderChange}
          className="w-full mx-4"
        />
        <span className="text-white">
          {videoProgress === 100 ? 'LIVE' : `-${Math.floor((videoRef2.current.duration - videoRef2.current.currentTime) / 60).toString().padStart(2, '0')}:${Math.floor((videoRef2.current.duration - videoRef2.current.currentTime) % 60).toString().padStart(2, '0')}`}
        </span>
      </div>

      <style>{`
        video::-webkit-media-controls {
          display: none !important;
        }
      `}</style>
    </div>
            </div>
}{
  (localStorage.getItem('isTheory').includes('True') && !localStorage.getItem('isDoubt').includes('True')) && !devicew &&
  <image className={`flex relative  ${isFullScreen?'h-full w-full':'h-[48vh] w-[70vw]'}`} >

  <div className={`${showQuiz ? "blur-sm" : ""} ${!isLoaded || l1 ?"blur-sm":""} ${isVideoVisible?'w-[100%]':'w-[100%]'} h-full flex justify-center items-center`} style={{ backgroundColor: 'black' }}>

  <image className="chalkboard" src={Blackboard}>
    <pre className="typing" ref={preRef}>
  <p style={{marginLeft:'10px',fontSize: 'calc(10px + 1vw)'}}>{displayText}</p>
  </pre>
</image>
</div>
{Number(localStorage.getItem('b'))==0&&
  <audio src={localStorage.getItem('language').includes('False')?Audi:c_h} autoPlay/>
}
</image>
}
{loading && !devicew &&
               <div className={`flex ${isFullScreen?'h-full w-full':'h-[50vh] w-[full]'} object-cover relative`} >
        <div className={`mosaic-container object-cover w-full ${isFullScreen?'h-full':'h-[80%]'}`}>
        <div className={`${showQuiz ? "blur-sm" : ""} ${!isLoaded || l1 ?"blur-sm":""} ${isVideoVisible?'w-[100%]':'w-[100%]'} h-full flex justify-center items-center`} style={{ backgroundColor: 'black' }}>
  <Loader />

</div></div>
  </div>
}
  {localStorage.getItem('isDoubt').includes('True') &&   !devicew &&  
               <div className={`flex ${isFullScreen?'h-full w-full':'h-[50vh] w-[100vh]'} relative`} >
                
      {/* Video 1 */}
      <div className={`${showQuiz ? "blur-sm" : ""} ${!isLoaded || l1 ?"blur-sm":""} ${isVideoVisible?'w-[60%]':'w-[100%]'} h-full flex justify-center items-center`} style={{ backgroundColor: '#006a7d' }}>
        <div className="caption absolute bottom-4 left-4 text-white bg-black bg-opacity-50 px-2 py-1 rounded-md z-20">
          {caption}
        </div>
        <div className={`mosaic-container object-cover w-full ${isFullScreen ? 'h-full' : 'h-full'}`}>
        <video
          ref={videoRef1}
          onClick={handlePlayPause}
          className="object-cover w-full h-full"
          onTransitionEnd={() => setAnimating(false)}
          style={{ transition: 'transform 1s',marginLeft:'10px' }}
          controls
          autoPlay
          onEnded={()=>{
            
            if(isDoubt &&localStorage.getItem('isCheck').includes('notknown')){
              
              localStorage.setItem('isCheck','alpha');
              console.log('appears')
              setdoubt(false)
              setIsVideoVisible(true)
              setTimeout(()=>{
              videoRef1.current.src=doubtanim
              videoRef2.current.src=localStorage.getItem('language').includes('False')?tenglish:thindi;
              videoRef1.current.load()
              videoRef2.current.load()
              videoRef2.current.muted=false
            },2000)}
          }}
          
        >
          <source src={doubtanim} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div className={`tiles ${showTransition ? "visible" : ""}`}>{tiles}</div>
        <div className={`xtiles ${showTransition1 ? "visible" : ""}`}>{xtiles}</div>

        </div>
      </div>

      {/* Video 2 */}
      <div className={`sm:flex items-right ${isVideoVisible?'w-[40%]':'w-[0%]'} h-full hidden ${showQuiz ? "blur-sm" : ""} ${!isLoaded || l1 ?"blur-sm":""}  ml-30 justify-end`} style={{ backgroundColor: '#086c7c' }}>
        <video
          ref={videoRef2}
          onTimeUpdate={handleTimeUpdate}
          onEnded={()=>{
            console.log(videoRef1.current.src)
            if(!isDoubt && localStorage.getItem('isCheck').includes('True')){
              setcaption('If you have any doubts feel free to ask')
              setstopper(false)
              const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
              const recognition = new SpeechRecognition();
              recognition.continuous = true;
              recognition.lang = 'en-US';
      recognition.onstart = function() {
        setRec(true)
          console.log("Recognition started.");
      };
          recognition.onerror = function(event) {
              console.error('Recognition error:', event.error);
              // Restart recognition unless there's a specific error that should stop it
              if (isRecognizing) {
                recognition.stop();  // Stop recognition if it's still running
            }
            setTimeout(()=>{recognition.start()},1000)
            
              
          };
              recognition.onresult = function(event) {
                setRec(false)
                const result = event.results[event.results.length - 1];
                const transcript = result[0].transcript.trim();
                console.log("transcript");
                console.log(transcript);
                console.log("transcript:", transcript);
                recognition.stop();
                if(transcript.includes('no')){
                  localStorage.setItem('mcq','0');
                  localStorage.setItem('b',Number(localStorage.getItem('b'))+1)
                  localStorage.setItem('isCheck','True');
                  localStorage.setItem('isDoubt','False');
                  setdoubt(false)
                  checkVideoAvailability(Number(localStorage.getItem('b')))
                  setcapt1(true)
                  setact(true)
                  setstopper(true)
                }else{
                  fetchresult2(2,flagged,transcript)
                  console.log(capt1)
                  localStorage.setItem('isCheck','notknown')
                  checkDoubtAvailability(1)
                }
              }
              recognition.start();
                  }else if(localStorage.getItem('isCheck').includes('alpha')){
                    localStorage.setItem('isDoubt','False');
                    localStorage.setItem('isCheck','True');
                    setdoubt(false)
                    localStorage.setItem('toskip','True')
                    checkVideoAvailability(Number(localStorage.getItem('b')));
                    }
                console.log('b value is'+localStorage.getItem('b'))
              }
          }
          autoPlay
          controls
          className="object-cover w-full h-full flex justify-end"
          style={{ backgroundColor: '#086c7c', display: isVideoVisible ? 'block' : 'none' }}
        >
          <source src={localStorage.getItem('language').includes('False')?doubtteacher:doubtteacher1} type="video/mp4" />
          Your browser does not support the video tag.
        </video>

        {/* Loader */}
        
      </div>
      {!isLoaded || l1 ? (
          <div className={`absolute inset-0 flex items-center justify-center z-10 ${!isLoaded || l1 ?"":"blur-sm"}`}>
            <img src={Thinking} className="loadingimg" alt="Loading..." />
            <p className='font-semibold font-[jost]'>Thinking...</p>
            
          </div>
         
        ) : null}
      {/* Quiz and controls */}
      {showQuiz && ( <div className="absolute inset-0 flex items-center justify-center z-30">
          <Quiz onClose={closeQuiz} />
        </div>)}


      <div className="absolute bottom-0 left-0 right-0 p-4 bg-black bg-opacity-50 flex items-center justify-between opacity-0 hover:opacity-100 transition-opacity duration-300 "
      onMouseEnter={() => {
        const captions = document.querySelectorAll('.caption');
        captions.forEach(caption => caption.style.bottom = '60px');
    }}
    style={{zIndex:'4'}}
    onMouseLeave={() => {
        const captions = document.querySelectorAll('.caption');
        captions.forEach(caption => caption.style.bottom = '12px');
    }}
      >
        <button onClick={handlePlayPause} className="text-white">
          {isPlaying ? 'Pause' : 'Play'}
        </button>
        <input
          type="range"
          min="0"
          max="100"
          value={videoProgress}
          onChange={handleSliderChange}
          className="w-full mx-4"
        />
        <span className="text-white">
          {videoProgress === 100 ? 'LIVE' : `-${Math.floor((videoRef2.current.duration - videoRef2.current.currentTime) / 60).toString().padStart(2, '0')}:${Math.floor((videoRef2.current.duration - videoRef2.current.currentTime) % 60).toString().padStart(2, '0')}`}
        </span>
      </div>

      <style>{`
        video::-webkit-media-controls {
          display: none !important;
        }
      `}</style>
    
            </div>
}
</div>
            <i id="fullscreen-toggle-btn" className="text-white cursor-pointer">[Fullscreen]</i>
            {(!localStorage.getItem('isTheory').includes('True') && !localStorage.getItem('isDoubt').includes('True')) &&  !loading && !devicew &&
            <div className="flex justify-center space-x-14 top-2 mb-8">
      <div className="text-center ">
           <a href="/path-to-file/notes.pdf" download="notes.pdf ">
              <button className="hover:shadow-[0_0_30px_rgba(255,255,0,0.7)] transition-all duration-300" onClick={downloadNotes}>
                  <img
                  src={DownloadNotes}
                  alt="Download Notes"
                  className="h-[12vh] mb-2 transition-all duration-300 hover:shadow-[0_0_20px_rgba(255,255,255,0.8)]"
                  />
              </button>
           </a>
      </div>

        <div className="text-center ">
                <button
                   onClick={() =>{
                    localStorage.setItem('videoDuration',videoRef2.current.currentTime)
                    localStorage.setItem('isDoubt','True')}} 
                   className="hover:shadow-[0_0_30px_rgba(255,255,0,0.7)] transition-all duration-300"
                >
                <img
                    src={AskDoubts}
                    alt="3D Mode"
                    className="h-[12vh] mb-2 transition-all duration-300 hover:shadow-[0_0_20px_rgba(255,255,255,0.8)]"
                />
                 </button>
        </div>
        </div>}
            <audio ref={audioRef} src={bg_music} loop volume />
<audio ref={audioRef1} src={mcq} onPlay={()=>{audioRef.current.volume=0.1}}/>
          <audio ref={keyhigh1} src={localStorage.getItem('language').includes('False')?keyhigh:k_h}/>
        </div>
    )
}
export default Video;