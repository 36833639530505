import React, { useState, useEffect } from "react";
import Typewriter from "typewriter-effect";
import { motion } from "framer-motion";

const Page2 = ({ id }) => {
    const language = localStorage.getItem("glanguage");
    const isHindi = language === "hindi";

    const details = [
        { title: isHindi ? "रियल टाइम" : "Instant Generation", content: isHindi ? "रियल टाइम में सामग्री उत्पन्न करता है" : "Creating content in real time" },
        { title: isHindi ? "विश्लेषण प्रश्न" : "Adaptive Questioning", content: isHindi ? "आपके आईक्यू के आधार पर प्रश्न उत्पन्न करता है" : "Personalized for your unique learning journey." },
        { title: isHindi ? "बहुभाषी समर्थन" : "Multilingual Support", content: isHindi ? "अंग्रेजी और हिंदी में सामग्री उत्पन्न करता है" : "Generating content in English, Hindi & Hinglish" },
        { title: isHindi ? "समृद्ध नोट्स" : "Comprehensive Notes", content: isHindi ? "आपको डाउनलोड करने योग्य नोट्स प्रदान करता है" : "Offering easily downloadable, well-structured notes" },
        { title: isHindi ? "विभिन्न मोड" : "Flexible Learning Modes", content: isHindi ? "शिक्षकों, छात्रों और सभी के लिए उपलब्ध" : "Designed for Teachers, Students and All" },
        { title: isHindi ? "साक्षात्कार मोड" : "Interview Mode", content: isHindi ? "आपके पीडीएफ पर आपका साक्षात्कार करने के लिए सेट" : "Continuosly engaging you with questions from your PDF." },
    ];

    const [glowIndex, setGlowIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setGlowIndex((prev) => (prev + 1) % details.length);
        }, 2000);
        return () => clearInterval(interval);
    }, []);

    return (
        <section id={id} className="relative w-full min-h-screen flex flex-col items-center justify-center bg-black text-white py-16 px-4 overflow-hidden">
            
            {/* Sci-Fi Background Lights */}
            <motion.div className="absolute top-10 left-10 w-48 h-48 bg-blue-500 bg-opacity-40 backdrop-blur-xl rounded-full blur-3xl opacity-50" />
            <motion.div className="absolute bottom-10 right-10 w-64 h-64 bg-purple-500 bg-opacity-40 backdrop-blur-xl rounded-full blur-3xl opacity-50" />

            {/* Title & Typewriter */}
            <motion.div className="relative text-center max-w-4xl z-10 p-8 bg-gradient-to-br from-white/10 to-white/5 backdrop-blur-md rounded-xl shadow-xl border border-white/20"
                initial={{ opacity: 0, y: 30 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 1, ease: "easeOut" }}>
                <h1 className="text-4xl md:text-6xl font-extrabold uppercase tracking-widest">
                    {isHindi ? "एबीआईवी सभी के लिए उपलब्ध है" : "ABIV is available for all"}
                </h1>

                <div className="flex justify-center mt-5 text-lg md:text-2xl font-semibold text-violet-500">
                    <Typewriter
                        options={{
                            strings: isHindi ? ["कक्षाओं", "शिक्षक", "आपके लिए"] : ["Classrooms", "Teachers", "You"],
                            autoStart: true,
                            loop: true,
                            deleteSpeed: 50,
                            delay: 100,
                        }}
                    />
                </div>

                <motion.p className="mt-6 text-lg md:text-xl lg:text-2xl text-gray-300 max-w-3xl mx-auto leading-relaxed">
                    {isHindi ? "रियल-टाइम वीडियो सारांश, बहुभाषी समर्थन, और इंटरैक्टिव लर्निंग का आनंद लें, कभी भी, कहीं भी।" 
                        : "Enjoy Real-Time Video Summaries, Multi-Language Support, And Interactive Learning Anytime, Anywhere."}
                </motion.p>
            </motion.div>

            {/* Features with Ultimate Sci-Fi FX */}
            <motion.div className="relative z-10 w-full max-w-6xl mt-16 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                {details.map((item, index) => (
                    <motion.div 
                        key={index} 
                        className={`relative w-full p-6 rounded-xl shadow-lg transition-transform transform cursor-pointer overflow-hidden
                            ${index % 2 === 0 ? "bg-violet-500 text-white border-gray-600" : "bg-[#6404FF] bg-opacity-70 border-white/20 text-blueviolet"} 
                            ${glowIndex === index ? "border-[3px] border-neon-glow scale-110" : "border-transparent"} 
                        `}
                        animate={{
                            y: [0, index % 2 === 0 ? -15 : 15, 0], // Up-down floating
                            rotateX: glowIndex === index ? [0, 15, -15, 0] : 0, // 3D Tilt
                            rotateY: glowIndex === index ? [0, 10, -10, 0] : 0,
                            scale: glowIndex === index ? [1, 1.05, 1] : 1, // Subtle scaling effect
                        }}
                        transition={{
                            duration: 3, 
                            repeat: Infinity, 
                            repeatType: "mirror",
                            ease: "easeInOut"
                        }}
                    >
                        {/* Futuristic Holographic Glitch Effect */}
                        <div className={`absolute inset-0 bg-gradient-to-r from-blue-500 via-purple-500 to-pink-500 opacity-0 ${
                            glowIndex === index ? "opacity-100 transition-opacity duration-700 blur-xl" : ""
                        } rounded-xl`} />

                        <h5 className="relative text-xl md:text-2xl font-bold text-white mb-2">{item.title}</h5>
                        <p className="relative text-sm md:text-lg mt-2 text-gray-200">{item.content}</p>
                    </motion.div>
                ))}
            </motion.div>

            {/* Closing Statement */}
            <motion.div className="relative z-10 mt-12 p-6 bg-gradient-to-br from-white/10 to-white/5 backdrop-blur-md border-2 border-blue-500 rounded-lg shadow-xl text-center max-w-3xl cursor-pointer"
                animate={{ y: [0, -10, 0] }}
                transition={{ duration: 3, repeat: Infinity, repeatType: "mirror", ease: "easeInOut" }}>
                <p className="text-lg md:text-xl font-semibold text-white">
                    {isHindi ? "एबीआईवी सुविधाओं में समृद्ध है और विभिन्न मोड में उपलब्ध है।"
                        : "ABIV is continuously delivering advanced features and adaptive modes, empowering individuals to learn and grow seamlessly."}
                </p>
            </motion.div>
        </section>
    );
};

export default Page2;
